<template>
  <div class='sub-page sub-page-leftmenu'>
    <div class='sub-tab-wrap'>
      <DxTabPanel
          id="tabpanel"
          :selected-index.sync="selectedIndex"
          :data-source='tabs'
          :focusStateEnabled='true'
        >
        <template #title="{ data: tab }">
        <div class='sub-title-wrap-tab'>
          <ul>
            <li class='float-left sub-title'>{{tab.title}}</li>
          </ul>
          </div>
        </template>
      </DxTabPanel>
    </div>
    <div class='sub-content'> <!-- 여기까지 page header 이하 수정해서 사용-->
      <dx-data-grid
        ref="refCoilPlanAndInputGrid"
        :data-source="coilPlanAndInputInfo"
        :show-borders="true"
        :showRowLines="false"
        :showColumnLines="true"
        :hover-state-enabled="true"
        :allowColumnResizing="true"
        column-resizing-mode="widget"
        :allow-column-reordering="true"
        :height="gridHeight"
        :row-alternation-enabled="true"
        :focused-row-enabled="true"
        key-expr="품번"
        :column-auto-width="true"
        :auto-navigate-to-focused-row="false"
        @toolbar-preparing="onToolbarPreparing"
        @cell-prepared="onCellPreparedOutput"
      >
        <DxSearchPanel :visible="true" placeholder="검색..." />
        <DxExport :enabled="true" :file-name="exportTitle" />
        <DxFilterRow :visible="true" apply-filter="auto" />
        <DxColumnFixing :enabled="false" />
        <DxColumn
          :allowEditing="false"
          :caption="$store.getters.getGroupName"
          data-field="차종"
          :min-width="50"
        >
          <DxLookup
            :data-source="차종리스트"
            display-expr="차종"
            value-expr="차종"
          />
        </DxColumn>
        <DxColumn :allowEditing="false"
          caption="품번"
          data-field="품번"
        >
        </DxColumn>
        <DxColumn
          :allowEditing="false"
          caption="품명"
          data-field="품명"
          :visible="true"
        >
        </DxColumn>
        <DxColumn caption="소요량" alignment="center">
          <DxColumn
            :allowEditing="true"
            caption="이월재고"
            data-field="이월재고"
            :visible="true"
            format="#,##0"
            :calculate-cell-value="calcStock"
          >
          </DxColumn>
          <DxColumn
            :allowEditing="true"
            caption="필요량"
            data-field="필요량"
            :calculate-cell-value="calcNeedStock"
            :visible="true"
            format="#,##0"
          >
          </DxColumn>
        </DxColumn>
        <DxColumn caption="계획 대비 입고" alignment="center">
          <DxColumn
            :allowEditing="true"
            caption="계획량"
            data-field="계획량"
            :visible="true"
            format="#,##0"
          >
          </DxColumn>
          <DxColumn
            :allowEditing="false"
            caption="입고량"
            data-field="입고량"
            :visible="true"
            format="#,##0"
          >
          </DxColumn>
          <DxColumn
            :allowEditing="true"
            caption="%"
            :visible="true"
            format='#0%'
            :calculate-cell-value="calcRate"
          >
          </DxColumn>
        </DxColumn>
        <DxColumn
          :allowEditing="false"
          caption="현재고"
          data-field="재공"
          :visible="true"
          format="#,##0"
        >
        </DxColumn>
        <DxColumn caption="금액" alignment="center">
          <DxColumn
            :allowEditing="false"
            caption="단가"
            data-field="가격"
            :visible="true"
            format="#,##0"
          >
          </DxColumn>
          <DxColumn
            :allowEditing="true"
            caption="계획"
            data-field="계획금액"
            :calculate-cell-value="calcPlanAmount"
            :visible="true"
            format="#,##0"
          >
          </DxColumn>
          <DxColumn
            :allowEditing="true"
            caption="입고"
            data-field="입고금액"
            :visible="true"
            format="#,##0"
            :calculate-cell-value="calcInputAmount"
          >
          </DxColumn>
        </DxColumn>
        <DxMasterDetail :enabled="true" template="coilOutputDetailTemplate" />
        <DxSummary>
          <DxTotalItem
            value-format="#,##0"
            column="차종"
            display-format="{0} (건)"
          />
          <DxTotalItem
            value-format="#,##0"
            summary-type="sum"
            column="계획금액"
            display-format="{0} 원"
          />
          <DxTotalItem
            value-format="#,##0"
            summary-type="sum"
            column="입고금액"
            display-format="{0} 원"
          />
        </DxSummary>
        <template #coilOutputDetailTemplate="{ data: item }">
          <CoilInputDetailTemp
            :item="item"
            :thisYear="thisYear"
            :thisMonth="thisMonth"
            :goodsType="goodsType"
          />
        </template>
        <dx-paging :enabled="false" />
        <dx-scrolling
          mode="virtual"
          showScrollbar="always"
          :useNative="false"
        />
        <dx-sorting mode="multiple" />
      </dx-data-grid>
      <loading :active.sync="isLoading" color='rgb(2, 110, 156)' loader='bars'></loading>
    </div> <!--sub-conten end-->
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import AppLib from '../../share/AppLib'
import DxTabPanel from 'devextreme-vue/tab-panel'
import DxDataGrid, {
  DxScrolling,
  DxFilterRow,
  DxColumnFixing,
  DxTotalItem,
  DxMasterDetail,
  DxSummary,
  DxColumn,
  DxPaging,
  DxSorting,
  DxSearchPanel,
  DxExport,
  DxLookup
} from 'devextreme-vue/data-grid'
import CoilInputDetailTemp from './CoilInputDetailTemp'
export default {
  name: 'CoilPlanAndDailyInput',
  data: function () {
    return {
      isLoading: false,
      selectedIndex: 0,
      tabs: [
        {
          title: '납품계획/실적'
        }
      ],
      vendorInfo: null,
      gridHeight: null,
      coilPlanAndInputInfo: null,
      exportTitle: null,
      yearArray: [],
      thisYear: null,
      monthArray: [],
      thisMonth: null,
      currentMonth: null,
      차종리스트: [],
      goodsType: ConstDef.외주품
    }
  },
  props: {},
  computed: {},
  components: {
    Loading,
    DxTabPanel,
    DxDataGrid,
    DxScrolling,
    DxFilterRow,
    DxColumnFixing,
    DxTotalItem,
    DxSummary,
    DxColumn,
    DxPaging,
    DxSorting,
    DxSearchPanel,
    DxExport,
    DxLookup,
    DxMasterDetail,
    CoilInputDetailTemp
  },
  created () {
    document.title = ConstDef.WEB_TAB_TITLE
    this.vendorInfo = this.$store.getters.getVendorInfo
    if (this.vendorInfo === undefined || this.vendorInfo === null) {
      this.$router.push('/')
      return
    }
    this.init()
  },
  mounted () {
    this.gridHeight = (screen.height - 250) * 0.95
  },
  updated () {},
  beforeDestroy: function () {},
  methods: {
    calcRate (row) {
      if (row.계획량 > 0) {
        return row.입고량 / row.계획량
      }
      return null
    },
    calcStock (row) {
      let 전월재고
      if (row.이월재공 <= 0) {
        전월재고 = 0
      } else {
        전월재고 = row.이월재공
      }
      return 전월재고
    },
    calcNeedStock (row) {
      let 전월재고
      let 필요량 = 0
      if (row.계획량 > 0) {
        if (row.재공 <= 0) {
          전월재고 = 0
        } else {
          전월재고 = row.이월재공
        }
        필요량 = row.계획량 - 전월재고
      }
      return 필요량
    },
    calcPlanAmount (row) {
      return row.계획량 * row.가격
    },
    calcInputAmount (row) {
      return row.입고량 * row.가격
    },
    init () {
      this.thisYear = AppLib.getThisYear()
      for (let i = this.thisYear; i >= 2021; i--) {
        this.yearArray.push({ year: i })
      }
      this.thisMonth = AppLib.getThisMonth()
      this.currentMonth = `${AppLib.getThisMonth()}`
      for (let i = 1; i <= 12; i++) {
        if (i > 0 && i < 10) {
          this.monthArray.push({ name: i + '월', value: '0' + i })
        } else {
          this.monthArray.push({ name: i + '월', value: '' + i })
        }
      }
      this.refreshPlanInputDataGrid(
        this.thisYear,
        this.thisMonth
      )
    },
    onCellPreparedOutput (e) {},
    onToolbarPreparing (e) {
      e.toolbarOptions.items.unshift(
        {
          location: 'before',
          template: function () {
            return '<div class="toolbar-label" style="margin-left:4px;color:rgb(4, 103, 189);"><b>연도 :  </b></div>'
          }
        },
        {
          location: 'before',
          widget: 'dxSelectBox',
          options: {
            width: 80,
            items: this.yearArray,
            valueExpr: 'year',
            displayExpr: 'year',
            value: this.thisYear,
            onValueChanged: args => {
              this.thisYear = args.value
              this.refreshPlanInputDataGrid(
                this.thisYear,
                this.thisMonth
              )
            }
          }
        },
        {
          location: 'before',
          template: function () {
            return '<div class="toolbar-label" style="margin-left:4px;color:rgb(4, 103, 189);"><b>월 :  </b></div>'
          }
        },
        {
          location: 'before',
          widget: 'dxSelectBox',
          options: {
            width: 80,
            items: this.monthArray,
            valueExpr: 'value',
            displayExpr: 'name',
            value: this.thisMonth,
            onValueChanged: args => {
              this.thisMonth = args.value
              this.refreshPlanInputDataGrid(
                this.thisYear,
                this.thisMonth
              )
            }
          }
        }
      )
    },
    refreshPlanInputDataGrid (year, month) {
      // this.isLoading = true
      this.exportTitle = `${this.thisYear}-${this.thisMonth}-외주품계획대비입고`
      const param = {
        startDate: `${year}-${month}-01`,
        endDate: `${year}-${month}-${AppLib.getMonthLastDay(year, month)} 23:59:59`,
        거래처코드: this.vendorInfo.거래처코드
      }
      this.$_sp
        .runNoEncodeFindProc('spFindAllPressPurchasePlanAndInputQtyByDateAndCompany', param)
        .then(data => {
          this.isLoading = false
          this.coilPlanAndInputInfo = this.$_sp.MakeModel(data)
          console.log(this.coilPlanAndInputInfo)
          this.차종리스트 = AppLib.getGroupByList(this.$_, this.coilPlanAndInputInfo, '차종')
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    }
  }
}
</script>

<style lang="scss"></style>
