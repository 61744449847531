<template>
  <div>
    <dx-data-grid
      ref="refCoilInputInfoDetaillGrid"
      :height='gridHeight'
      :data-source="coilInputInfoDetail"
      :show-borders = 'true' :showRowLines = 'false' :showColumnLines='true'
      :hover-state-enabled = "true" :allowColumnResizing = 'true'
      column-resizing-mode = 'widget' :allow-column-reordering = "true"
      :row-alternation-enabled="true"
      :focused-row-enabled="true"
      :auto-navigate-to-focused-row="true"
      @cell-prepared="onCellPrepared"
      key-expr="seqId"
      @toolbar-preparing='onToolbarPreparing'
    >
      <DxSearchPanel
        :visible="true"
        placeholder="검색..."
      />
      <DxExport
        :enabled="true"
        :file-name="exportTitle"
      />
      <DxFilterRow
        :visible="true"
        apply-filter="auto"
      />
      <DxColumn
        :allowEditing='false'
        caption="입출고일"
        data-field="발생일자"
        dataType='date'
        format='yyyy-MM-dd'>
      </DxColumn>
      <DxColumn
        :allowEditing='false'
        caption="품번"
        data-field="품번"
      >
      </DxColumn>
      <DxColumn
        :allowEditing='false'
        :fixed="false"
        caption="품명"
        data-field="품명"
        :visible='true'
      >
      </DxColumn>
      <DxColumn v-if="goodsType==='S0402'"
        :allowEditing='false'
        caption="재질"
        data-field="재질"
        :visible='true'
      >
      </DxColumn>
      <DxColumn
        :allowEditing='true'
        caption="수량"
        data-field="수량"
        :visible='true'
        format='#,##0'
        dataType="number"
      >
      </DxColumn>
      <DxColumn
        :allowEditing='false'
        caption="단가"
        data-field="단가"
        :visible='true'
        format='#,##0'
        dataType="number"
      >
      </DxColumn>
      <DxColumn
        :allowEditing='false'
        caption="금액"
        data-field="금액"
        :visible='true'
        format='#,##0'
        dataType="number"
      >
      </DxColumn>
      <DxColumn
        :allowEditing='false'
        :visible="false"
        caption="거래처"
        data-field="거래처명"
      >
      </DxColumn>
        <DxColumn
        :allowEditing='false'
        caption="LOT_NO"
        data-field="lotNo"
        :visible='true'
      >
      </DxColumn>
      <DxSummary>
        <DxTotalItem
          value-format='#,##0'
          column="발생일자"
          display-format="{0} (건)"
        />
        <DxTotalItem
          value-format='#,##0'
          summary-type="sum"
          column="수량"
          display-format="{0}"
        />
        <DxTotalItem
          value-format='#,##0'
          summary-type="sum"
          column="금액"
          display-format="{0}"
        />
      </DxSummary>
      <dx-paging :enabled="false"/>
      <dx-scrolling mode="virtual" showScrollbar='always' :useNative='false'/>
      <dx-sorting mode="multiple"/>
    </dx-data-grid>
  </div>
</template>

<script>
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import AppLib from '../../share/AppLib'
import DxDataGrid, {
  DxScrolling, DxFilterRow, DxExport, DxTotalItem,
  DxSummary, DxColumn, DxPaging, DxSorting, DxSearchPanel
} from 'devextreme-vue/data-grid'

export default {
  name: 'RawMaterialsInOutDetailTemp',
  data: function () {
    return {
      gridHeight: 300,
      coilInputInfoDetail: null,
      meterialTypeLookup: null,
      unitLookup: null,
      excelFileName: null,
      mrpExcelExportInfo: [],
      receiptTypeLookup: null,
      exportTitle: null
    }
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    thisYear: {
      type: Number,
      default: 2022
    },
    thisMonth: {
      type: String,
      default: '04'
    },
    isYear: {
      type: Boolean,
      default: false
    },
    goodsType: {
      type: String,
      default: ConstDef.자재유형_원자재
    }
  },
  components: {
    DxColumn,
    DxScrolling,
    DxPaging,
    DxDataGrid,
    DxExport,
    DxSummary,
    DxTotalItem,
    DxSorting,
    DxSearchPanel,
    DxFilterRow
  },
  created () {
  },
  mounted () {
    this.refreshPurchaseDetail(this.item.data.입고업체)
    if (this.isYear) {
      this.exportTitle = `${this.thisYear}년-${this.item.data.품번}(${this.item.data.품명})-입고내역서`
    } else {
      this.exportTitle = `${this.thisYear}년${this.thisMonth}월-${this.item.data.품번}(${this.item.data.품명})-입고내역서`
    }
  },
  updated () {
  },
  beforeDestroy: function () {

  },
  methods: {
    onCellPrepared (e) {
      // if (e.rowType === 'data' && e.column.dataField === '금액' && e.data.입출고구분 === ConstDef.사급출고) {
      //   this.$Q(e.cellElement).css('color', 'red')
      // }
    },
    onToolbarPreparing (e) {
    },
    refreshPurchaseDetail (company) {
      const param = {
        startDate: `${this.thisYear}-${this.thisMonth}-01`,
        endDate: `${this.thisYear}-${this.thisMonth}-${AppLib.getMonthLastDay(this.thisYear, this.thisMonth)} 23:59:59`,
        품번: this.item.data.품번
      }
      this.$_sp.runNoEncodeFindProc('spFindAllCoilInputQtyByDate', param)
        .then((data) => {
          this.coilInputInfoDetail = this.$_sp.MakeModel(data)
          console.log(this.coilInputInfoDetail)
          console.log(this.goodsType)
        })
        .catch(error => {
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    }
  }
}
</script>

<style lang="scss">
.dx-master-detail-cell {
  padding-top: 8px!important;
}
</style>
